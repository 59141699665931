.contact-page-body {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
}

.row.contact-page-row {
    margin-top: 150px;
    margin-bottom: 150px;
}

.contact-page-body h1 {
    font-family: "Millik";
    font-size: 44px;
    font-weight: 400;
    color: #000;
    margin-top: 80px;
    margin-bottom: 40px;
}

.contact-page-body h3 {
    font-family: "Millik";
    font-size: 24px;
    font-weight: 400;
    color: #000;
    /* margin-top: 80px;
    margin-bottom: 40px; */
}

.contact-page-body p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    margin-bottom: 25px;
    margin-top: 0;
}