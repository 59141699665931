.droplove-pro-body {
  padding-top: 80px;
}

.container-fluid {
  /* padding-left: 0px;
  padding-right: 0px; */
}

.droplove-pro-mintpass {
  margin-bottom: 80px;
}

.droplove-pro-mintpass h1 {
  font-family: "Millik";
  font-size: 96px;
  font-weight: 400;
  color: #181743;
  margin-top: 60px;
  margin-bottom: 20px;
}

.droplove-pro-mintpass h4 {
  font-size: 24px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #181743;
  margin: 20px auto;
  line-height: 36px;
}

.mintpass-pro-section {
  padding: 80px;
  border: 2px solid #6d6af4;
  box-sizing: border-box;
  box-shadow: 0px 0px 47px rgb(190 189 255);
  border-radius: 35px;
  width: 75%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  background-color: #fff;
}

.mintpass-pro-section img {
  width: 75px;
}

.mintpass-pro-section h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
  margin: 35px 20px;
}

.mintpass-pro-section p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #181743;
  line-height: 24px;
  text-align: center;
}

.mintpass-section-top-miniheading {
  margin-bottom: 30px;
}

.mintpass-section-top-miniheading p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.mintpass-section-bottom {
  margin-top: 80px;
}

.mint-counter {
  margin-bottom: 20px;
}

span.mintpass-counter {
  font-family: "Poppins", sans-serif;
  color: #2407d8;
  font-weight: 600;
  font-size: 32px;
  vertical-align: middle;
}

.mint-counter img {
  margin: 0px 30px;
  cursor: pointer;
}

.pay-with-card-btn-section {
  width: auto;
  height: auto;
  margin-top: 30px;
}

.price-info {
  font-weight: 600 !important;
  font-size: 16px !important;
  margin: 25px 0px;
}

.droplove-pro-shamans-section h2 {
  font-family: "Millik";
  font-size: 60px;
  font-weight: 400;
  color: #181743;
  margin-top: 60px;
  margin-bottom: 20px;
}

.droplove-pro-shamans-section h4 {
  font-size: 24px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #181743;
  margin: 20px auto;
  width: 80%;
}

.droplove-pro-shamans {
  padding: 60px 100px;
  width: 100%;
}

.shamans-item {
  width: 20%;
  float: left;
  padding: 20px;
}

.shamans-item-inner {
  background: #ffffff;
  box-shadow: 0px 0px 47px #bebdff;
  border-radius: 35px;
}

.shamans-item-top {
  overflow: hidden;
}

.shamans-item-top img {
  width: 100%;
  transition: transform .2s;
}

.shamans-item-top img:hover {
  transform: scale(1.1);
}

.shamans-item-bottom {
  height: 120px;
  padding: 20px;
}

.shamans-item-bottom p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  text-align: left;
}

.droplove-pro-why-drplv {
  background: linear-gradient(255.49deg, #e0dfff 15.75%, #edfff7 84.25%);
  position: relative;
  padding: 80px 100px;
}

.droplove-pro-why-drplv h2 {
  font-family: "Millik";
  font-size: 60px;
  font-weight: 400;
  color: #181743;
  margin-top: 60px;
  margin-bottom: 40px;
}

.droplove-pro-why-drplv p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #181743;
  line-height: 24px;
  text-align: center;
  line-height: 36px;
}

.upcoming-drop-title {
  margin-bottom: 40px;
  border-bottom: 1px solid #000;
}

.upcoming-drop-title h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: black;
  text-align: left;
}

.upcoming-drop-title .homepage-right-view-all {
  text-align: right;
  margin-bottom: 10px;
}

.upcoming-drop-title .homepage-right-view-all a {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #616161;
}

.homepage-right-view-all a:hover {
  text-decoration: none;
}

.upcoming-drop-window {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  padding: 30px 0px;
  /* width: 70%; */
  margin: auto;
  box-shadow: 0px 0px 47px rgb(190 189 255);
  background: #ffffff;
  border-radius: 35px;
  margin-bottom: 20px;
}

.udw-md-margin {
}

.upcoming-img {
  margin-bottom: 30px;
}

.upcoming-img img {
  border-radius: 35px;
  min-height: 300px;
}

.upcoming-info-avatar {
  padding: 15px;
}

.upcoming-info span {
  font-weight: 600;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #181743;
}

.full-btn-upcomming {
  background-color: #5034ff;
  padding: 12px 20px;
  color: white;
  border-radius: 28px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.full-btn-upcomming:disabled {
  background-color: #bec7cc;
}

.upcoming-info {
  text-align: center;
  padding: 0px 30px;
}

.upcoming-info h3 {
  font-family: "Millik";
  font-size: 24px;
  font-weight: 400;
  color: #181743;
  margin: 20px 0px;
}

.upcoming-info p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #181743;
  margin: 20px 0px;
}

.droplove-pro-faq-section {
  padding: 60px 100px;
}

.droplove-pro-faq-section h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 60px;
  color: #000;
  margin-top: 80px;
  margin-bottom: 80px;
}

.faq-item {
  margin-bottom: 80px;
}

.droplove-pro-faq-section h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: #000;
  margin-bottom: 20px;
}

.droplove-pro-faq-section p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #000;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .droplove-pro-mintpass h1 {
    font-size: 60px;
  }

  .droplove-pro-mintpass h4 {
    line-height: 30px;
    font-size: 20px;
  }
  
  .mintpass-pro-section {
    width: 100%;
    padding: 40px 15px;
  }

  .mintpass-section-top-miniheading p {
    width: 90%;
    margin: 0 auto;
  }

  .mint-counter img {
    margin: 0px 15px;
  }

  .droplove-pro-shamans-section {
    text-align: center;
  }

  .droplove-pro-shamans-section h4 {
    width: 100%;
  }

  .droplove-pro-shamans {
    padding: 60px 0px;
  }

  .shamans-item {
    width: 50%;
  }

  .droplove-pro-why-drplv {
    padding: 60px 0px;
  }

  .droplove-pro-faq-section {
    padding: 60px 0px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  
}